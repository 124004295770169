import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import LoginWithGoogle from './LoginWithGoogle';
import Message from '../../../../Components/Message/Message';
import oceanapi, { getUserName, userLogin, mailSent } from "../../../../Base/OceanConfig";
import { setSession } from '../../../../Utils/Common';
import { ColorContext } from '../../../../Components/ColorContext/ColorContext';
import styles from '../login-modal.module.css';

export default function SignIn(props){
    const { domain } = useContext(ColorContext);
    const { css } = useContext(ColorContext);
    const { status } = useContext(ColorContext);
    const { loginWithGoogle } = useContext(ColorContext);
    const { createAccount } = useContext(ColorContext);
    const { copyRight } = useContext(ColorContext);
    const { showForgotPassword } = useContext(ColorContext);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [recoverEmail, setRecoverEmail] = useState('');
    const [extraction, setExtraction] = useState(false);
    const [year, setYear] = useState('');

    useEffect(() => {
        const d = new Date();
        let year = d.getFullYear();
        setYear(year);
        fetchUserName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchUserName = () => {
        oceanapi.get(getUserName + domain)
        .then((response) => {
            setLoading(false);
            if(response.data.code === 200){
                setUserName(response.data.result);
            } else {
                setUserName('');
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        })
    }

    const loginFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        oceanapi.post(userLogin, {
            email: email,
            password: password,
            userName: userName
        })
        .then(res => {
            if(res.data.code === 200){
                setSession(res.data.token);
                window.location.replace(props.redirectPath);
                setLoading(false);
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setLoading(false);
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        }).catch(error => {
            setLoading(false);
            setMessageClass('error');
            console.log(error)
            setMessage("Something went wrong. try again later")
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    const sentMail =(e)=>{
        e.preventDefault();
        setLoading(true);
        oceanapi.post(mailSent, {
            email: recoverEmail,
            parentName: userName
        })
        .then(res => {
            if(res.data.code === 200){
                setLoading(false);
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
                setRecoverEmail("")
                setForgotPassword(false);
                setExtraction(true);
            } else {
                setLoading(false);
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        }).catch(error => {
            setLoading(false);
            setMessageClass('error');
            console.log(error)
            setMessage("Something went wrong. try again later")
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}
        {extraction &&
            <div className={styles['custom-container']}>
                <div className={styles['custom-background']} onClick={() =>setExtraction(false)}></div>
                <div className={styles['pop-up-form-custom']}>
                    <img src='./images/verify-email.png' alt="extraction" />
                    <h4>Verify email !</h4>
                    <p>We will send you a link to verify your email address, click on it to confirm </p>
                </div>
            </div>
        }
        {forgotPassword && <div className="modal-container">
            <div className="modal-background" onClick={() => setForgotPassword(false)}></div>
            <div className="modal-box">
                <form className={styles["export-box"]} onSubmit={sentMail}>
                    {/* <div className={styles["back-btn"]}><i className="fa fa-arrow-left"><span onClick={()=>setForgotPassword(false)}> Back </span></i></div> */}
                    <div className={styles["back-btn"]} style={css.color} onClick={()=>setForgotPassword(false)}><i className="fa fa-window-close" aria-hidden="true"></i></div>
                    <h4>Forgot Password?</h4>
                    <br/>
                    <h4>Enter the email address associate with your account.</h4>
                    <p>We will email you a link to reset your password.</p>
                    <label>Email</label>
                    <input style={css.borderLeftColor} type="email" placeholder="Enter email" value={recoverEmail} onChange={e => setRecoverEmail(e.target.value)} onMouseEnter={e=> setRecoverEmail(e.target.value.toLowerCase())} required/>
                    {
                        loading
                        ?<button className="btn primary-button w-100 mt-3" style={css.primaryButton} disabled> Wait... </button>
                        :<button type="submit" className="btn primary-button w-100 mt-3" style={css.primaryButton}>Send</button>
                    }
                </form> 
            </div>
        </div>}
        <div className={styles[props.page === 'login-page'?"login-page-content":"registration-modal-content"]}>
            <form onSubmit={loginFunction}>
                <label>Email</label>
                <input style={css.borderLeftColor} type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} onMouseEnter={e=> setEmail(e.target.value.toLowerCase())} required/>
                <label>Password</label>
                <input style={css.borderLeftColor} type={showPassword ? 'text' : 'password'} autoComplete='new-password' placeholder="Enter password" value={password} onChange={e => setPassword(e.target.value)} required/>
                <div className={styles['hide-show-password']}>
                    <i className={`fa fa-eye${showPassword ? '' : '-slash'}`} onClick={() => setShowPassword(!showPassword)}></i>
                </div>
                {status && <>
                    <input type="checkbox" required />{" "}
                    <span className={styles["terms-and-conditions"]}>
                        I agree to <Link style={css.color} to="/terms-conditions" target="_blank"> Terms & Conditions </Link>
                    </span>
                </>}
                {showForgotPassword && <span className={styles["forgot-password"]} onClick={()=>setForgotPassword(true)}>Forgot password</span>}
                {
                    loading
                    ?<button className="btn primary-button w-100 mt-3" style={css.primaryButton} disabled> Wait... </button>
                    :<button type="submit" className="btn primary-button w-100 mt-3" style={css.primaryButton}> Sign In </button>
                }
            </form>
            {loginWithGoogle && <>
                <div className={styles['or']}>
                    <hr/>
                    <span>OR</span>
                    <hr/>
                </div>
                <LoginWithGoogle userName={userName} createAccount={createAccount} redirectPath={props.redirectPath} />
            </>}
            {createAccount && <p className={styles['have-account']}>Don't have an account? <span style={css.color} onClick={() => props.toggleRegistration(true)}>Sign Up</span></p>}
            {/* <div className={styles['certified-logo']}>
                <img src='./images/certified-4.svg' />
                <img src='./images/certified-3.svg' />
                <img src='./images/certified-2.svg' />
                <img src='./images/certified-1.svg' />
            </div> */}
            <div className={styles['footer-content']}>
                <p>{copyRight ? copyRight : `© ${year} | All rights reserved`}</p>
            </div>
        </div>
    </>);
};