import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import Message from '../../../../Components/Message/Message';
import oceanapi, { loginByGoogleToken, registrationByGoogleToken } from "../../../../Base/OceanConfig";
import { setSession } from '../../../../Utils/Common';
import styles from '../login-modal.module.css';

export default function LoginWithGoogle(props){
    const userName = props.userName;
    const createAccount = props.createAccount;
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    const login = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                loginWithGoogleFunction(response.access_token)
            } catch (error) {
                console.log(error)
            }
        }
    })

    const loginWithGoogleFunction = (token) => {
        oceanapi.post(loginByGoogleToken, {
            token: token,
            userName: userName
        })
        .then(res => {
            setTimeout(() => {
                setMessage('');
            }, 5000);
            if(res.data.code === 200) {
                setSession(res.data.token);
                window.location.replace(props.redirectPath);
                setMessageClass('success');
                setMessage(res.data.message);
            } else if(res.data.code === 401) {
                if(createAccount) {
                    const splitValues = res.data.message.split(' ');
                    if(splitValues[2] + " " + splitValues[3] === "not found") {
                        registrationWithGoogleFunction(token);
                    }
                } else {
                    setMessage(res.data.message);
                }
                setMessageClass('error');
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
            }
        }).catch(error => {
            setMessageClass('error');
            console.log(error)
            setMessage("Something went wrong. try again later")
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    const registrationWithGoogleFunction = (token) => {
        oceanapi.post(registrationByGoogleToken, {
            token: token,
            userName: userName
        })
        .then(res => {
            setTimeout(() => {
                setMessage('');
            }, 5000);
            if(res.data.code === 200) {
                setMessageClass('success');
                loginWithGoogleFunction(token)
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
            }
        }).catch(error => {
            setMessageClass('error');
            console.log(error)
            setMessage("Something went wrong. try again later")
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')}/>}
        <div onClick={() => login()} className={styles['login-option']}>
            <img src="../images/google-icon.svg" alt="google-icon" /> Sign in with Google
        </div>
    </>);
};